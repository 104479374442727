<script>
export default {
  page: {
    title: 'Home',
  },
  components: {
    AppBar: () => import('@components/structure/app-bar.vue'),
    Footer: () => import('@components/structure/footer.vue'),
  },
}
</script>

<template>
  <v-app>
    <AppBar />
    <v-main class="mb-10 mt-4">
      <router-view :key="$route.fullPath"></router-view>
    </v-main>
    <Footer />
  </v-app>
</template>
